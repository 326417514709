import axios from 'axios';
import browserInfo from 'browser-info';

class ClientSideLogger {
    formatMessage(props){
        let message = {props};
        message.date = new Date();
        message.browserInfo = browserInfo();
        return message;
    }

    onerror(props){
        if(window.loggingInformation){
            const logInfo = window.loggingInformation;

            const message = this.formatMessage(props);
            message.loggingInformation = logInfo;

            axios.post(logInfo.apiEnd, {messageJson: JSON.stringify(message)}).catch();
        }
    }
}

const instance = new ClientSideLogger();

export default instance;